import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import Button from '../button/button.component';
import { Loader } from 'react-feather';
import styles from './serviceWorkerManager.style';
import PWAPrompt from 'react-ios-pwa-prompt'
import * as serviceWorker from '../../serviceWorker';
import * as firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyDwVpOShnW1TLyC2vevjvvI721beJFjbMM",
  authDomain: "corona-271209.firebaseapp.com",
  databaseURL: "https://corona-271209.firebaseio.com",
  projectId: "corona-271209",
  storageBucket: "corona-271209.appspot.com",
  messagingSenderId: "826995517611",
  appId: "1:826995517611:web:08092373cf720e43052fee",
  measurementId: "G-E37Z8ZF3RX"
};

firebase.initializeApp(firebaseConfig);

firebase.analytics();
const isApple = ['ios', 'apple', 'safari'].some(item => navigator.userAgent.toLowerCase().includes(item)) && !navigator.userAgent.toLowerCase().includes('chrome');
let messaging;
if (!isApple) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey('BI4Ad9FRVirY-BhZyeor0GoF7Vl9KFQHRwtzUDw-3-4r7E1atrMLHS9B5tG98P8jwzHMSjpJpziOGKjGymh9gTI');
}

const ServiceWorkerManager = () => {
  const [newServiceWorker, setNewServiceWorker] = useState();
  const [serviceWorkerAvailable, setServiceWorkerAvailable] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [messageReceived, setMessageReceived] = useState(false);
  const [message, setMessage] = useState({
    heading: '',
    message: '',
  });
  if (!isApple) {
    messaging.getToken().then(reg => console.log('Firebase messaging success, Token: ', reg));
    messaging.onMessage((payload) => {
      console.log('Message received. ', payload);
      setMessage({
        heading: payload.notification.title,
        message: payload.notification.body,
      });
      setMessageReceived(true);
    });
  } else {
    console.log('Apple safari browser, not registering for push notification');
  }
  const updateServiceWorker = () => {
    setServiceWorkerAvailable(false);
    setUpdating(true);
    if (newServiceWorker) {
      const registrationWaiting = newServiceWorker.waiting;
      if (registrationWaiting) {
        registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
        registrationWaiting.addEventListener('statechange', e => {
          if (e.target.state === 'activated') {
            window.location.reload();
          }
        });
      }
    } else {
      window.location.reload();
    }
  };
  serviceWorker.register({
    onUpdate: reg => {
      console.log('Service worker updated ...');
      setNewServiceWorker(reg);
      setServiceWorkerAvailable(true);
    },
    onRegistration: reg => {
      console.log('Service worker registration done ...');
    }
  });
  return (
    <>
      <Modal open={messageReceived} onClose={() => setServiceWorkerAvailable(false)}>
        <div style={{ minWidth: 200 }}>
          <h2>{message.heading}</h2>
          <span style={{ fontSize: 20 }}>{message.message}</span>
          <div style={{ marginTop: 10 }}>
            <Button title="Ok" onClick={() => {
              setMessage({
                heading: '',
                message: '',
              });
              setMessageReceived(false);
            }} />
          </div>
        </div>
      </Modal>
      <Modal open={serviceWorkerAvailable} onClose={() => null} closeOnEsc={false} closeOnOverlayClick={false}>
        <h2>Update Available</h2>
        <span style={{ fontSize: 20 }}>There is an update for the app! Press the button below to activate the new update</span>
        <div style={{ marginTop: 10 }}>
          <Button title="Update" onClick={() => updateServiceWorker()} />
        </div>
      </Modal>
      {updating && (
        <div style={styles.backdrop}>
          <Loader className="spinner" size={40} color="white" style={{ marginRight: 10 }} /> Updating ...
        </div>
      )}
      <PWAPrompt />
    </>
  )
};

export default ServiceWorkerManager;
