import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-responsive-modal';
import Button from '../button/button.component';

const AlertModal = ({ message, heading, buttonTitle, onClick, onClose }) => {
  const [open, setOpen] = useState(true);
  const close = () => {
    setOpen(false);
    onClose();
  }

  const click = () => {
    setOpen(false);
    onClick();
  }

  return (
    <Modal open={open} onClose={close}>
      <div style={{ minWidth: 200 }}>
        <h2>{heading}</h2>
        <span style={{ fontSize: 20 }}>{message}</span>
        <div style={{ marginTop: 10 }}>
          <Button title={buttonTitle || 'OK'} onClick={click} />
        </div>
      </div>
    </Modal>
  );
};

const Alert = {
  alert: ({
    heading,
    message,
    onClick = () => {
      console.log('onClick');
    },
    onClose = () => {
      console.log('onClose');
    },
    buttonTitle,
  }) => {
    const close = () => {
      onClose();
      setTimeout(() => ReactDOM.unmountComponentAtNode(document.getElementById('modal')), 1);
    };
    const click = () => {
      onClick();
      setTimeout(() => ReactDOM.unmountComponentAtNode(document.getElementById('modal')), 1);
    };
    return ReactDOM.render(
      <AlertModal buttonTitle={buttonTitle} heading={heading} message={message} onClick={click} onClose={close} />,
      document.getElementById('modal')
    )
  },
}

export default Alert;
