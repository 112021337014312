import React from 'react';
import styles from './statCard.style';
import PropTypes from 'prop-types';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const StatCard = ({ heading, counter, loading }) => {
  if (loading) {
    return (
      <SkeletonTheme color="#363636" highlightColor="#434343">
        <div style={styles.loadingContainer}>
          <div style={styles.loadingInner}>
            <Skeleton width="50%" height="3vh" />
            <div style={{height: 10}} />
            <Skeleton width="60%" height="6vh" />
          </div>
        </div>
      </SkeletonTheme>
    );
  }
  return (
    <div style={styles.container}>
      <span style={styles.heading}>{heading}</span>
      <span style={styles.counter}>{counter}</span>
    </div>
  );
};

StatCard.propTypes = {
  heading: PropTypes.string.isRequired,
  counter: PropTypes.number.isRequired,
};

export default StatCard;
