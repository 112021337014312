import React, { useContext } from 'react';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import styles from './header.style';
import { ArrowLeft, RefreshCcw } from 'react-feather';
import { ApiContext } from '../../api/apiWrapper';

const swapIcon = require('../../assets/icons/swap.svg');

const Header = () => {
  const location = useLocation();
  const history = useHistory();
  const context = useContext(ApiContext);
  const headerMaps = {
    '/countries': {
      heading: 'Countries',
      refresh: 'refreshCountryData',
      back: true,
    },
    '/map': {
      heading: 'World Map',
      refresh: 'refreshCountryData',
      back: true,
    },
    '/': {
      heading: 'Coronavirus Stats',
      refresh: context.showFavourites ? 'refreshCountryData' : 'refreshSummaryData',
      back: false,
      toggleFavourites: true,
    },
  }
  if (headerMaps[location.pathname]) {
    return (
      <div style={styles.headerContainer}>
        <div style={styles.left}>
          {headerMaps[location.pathname].toggleFavourites && <img className="swap-btn" src={swapIcon} onClick={() => context.toggleFavourites()}/>}
          {headerMaps[location.pathname].back && <ArrowLeft className="back-btn" color="#CDAE70" size={40} onClick={() => history.push('/')} />}
        </div>
        <div className="page-heading" style={styles.middel}>
          {headerMaps[location.pathname].heading}
        </div>
        <div style={styles.right}>
          <RefreshCcw className="refresh-btn" color="#CDAE70" size={40} onClick={() => context[headerMaps[location.pathname].refresh]()} />
        </div>
      </div>
    )
  } else {
    return <Redirect to="/" />
  }
};

export default Header;