const TutorialSteps = {
  main: [
    {
      target: '.page-heading',
      content: 'Welcome to the KeyboardNinjas Coronavirus statistics app. You are currently on the home screen. This will be a quick tutorial on how to navigate and use the application. Also remember that if you add this application to your home screen then it will function as an installed application! Yay technology :)! You can either skip the tutorial by clicking the skip button or you can continue by pressing next.',
    },
    {
      target: '.refresh-btn',
      content: 'This button let\'s you refresh your data to ensure it is up to date.',
    },
    {
      target: '.swap-btn',
      content: 'This button let \'s you switch between global statistics and your favourite countries that you can setup in the countries section',
    },
    {
      target: '.countries-btn',
      content: 'This button will take you to the countries section where you can view a quick summary of each country that has been affected thus far.',
    },
    {
      target: '.map-btn',
      content: 'This button will take you to the world map section where you can see each affected area in the world.',
    },
  ],
  countries: [
    {
      target: '.page-heading',
      content: 'Welcome to the countries section, here you will be able to search and favourite items that you want to view quickly on your home screen. You can either skip the tutorial by clicking the skip button or you can continue by pressing next',
    },
    {
      target: '.searchbar-input',
      content: 'You can filter the countries by typing in the country name here.',
    },
    {
      target: '.refresh-btn',
      content: 'This button lets you refresh your country data to ensure it is the latest data.',
    },
    {
      target: '.back-btn',
      content: 'This button let\'s you go back to the home screen.',
    },
    {
      target: '.favourite-btn',
      content: 'This button let\'s you add a country to your favourite list. Your favourite items can be easily viewed on your home screen if you click the swap button in the left top corner of the home screen. You can only have 3 favourites at a time and thus you should unfavourite one if you wish to swap it with another one.',
    },
  ],
  map: [
    {
      target: '.page-heading',
      content: 'Welcome to the map section, here you will be able to see a world map of all the infected areas. You can either skip the tutorial by clicking the skip button or you can continue by pressing next',
    },
    {
      target: '.refresh-btn',
      content: 'This button lets you refresh your country data to ensure it is the latest data.',
    },
    {
      target: '.back-btn',
      content: 'This button let\'s you go back to the home screen.',
    },
    {
      target: '.map-marker',
      content: 'Click any of these markers to see what country it is and what the current stats of that country is.',
    },
  ]
};

export default TutorialSteps;
