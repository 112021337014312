const styles = {
  container: {
    padding: '20px 10px',
    margin: '0 10px',
    boxSizing: 'border-box',
    borderBottom: '1px solid #CDAE70',
    position: 'relative',
    display: 'flex',
    flex: '1 0',
  },
  name: {
    color: 'white',
    fontSize: 20,
  },
  amount: {
    color: '#686868',
  },
};

export default styles;
