import React, { useContext } from 'react';
import styles from './countryItem.style';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ApiContext } from '../../api/apiWrapper';

const star = require('../../assets/icons/star-regular.svg')
const starFilled = require('../../assets/icons/star-solid.svg');

const CountryItem = ({
  country = {
    attributes: {}
  },
  loading,
}) => {
  const {
    attributes: {
      recovered,
      confirmed,
      deaths,
      Country_Region: title,
    },
  } = country;
  const {
    favourites,
    addFavourite,
    removeFavourite,
  } = useContext(ApiContext);
  const favourited = favourites.includes(title);
  return (
    <>
      {!loading && (
        <div style={styles.container}>
          <div>
            <div style={styles.name}>{title}</div>
            <div style={styles.amount}>Confirmed: {confirmed}</div>
            <div style={styles.amount}>Deaths: {deaths}</div>
            <div style={styles.amount}>Recovered: {recovered}</div>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <img
              className="favourite-btn"
              alt="favourite"
              height="40"
              width="40"
              src={favourited ? starFilled : star}
              onClick={() => {
                if (favourited) {
                  removeFavourite(title);
                } else {
                  addFavourite(title);
                }
              }} />
          </div>
        </div>
      )}
      {loading && (
        <div style={styles.container}>
          <div style={{ width: '100%' }}>
            <SkeletonTheme color="#363636" highlightColor="#434343">
              <Skeleton width={160} height={18} /><br />
              <Skeleton width={120} /><br />
              <Skeleton width={125} /><br />
              <Skeleton width={145} /><br />
            </SkeletonTheme>
          </div>
        </div>
      )}
      <div style={styles.lineDivider} />
    </>
  )
};

export default CountryItem;
