const styles = {
  container:{
    fontSize: 35,
    display: 'flex',
    height: '8vh',
    width: '100%',
    backgroundColor: '#CDAE70',
    alignItems: 'center',
    color: 'white',
    justifyContent: 'center',
    border: '1px solid white',
    borderRadius: 10,
  }
};

export default styles;