const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '16vh',
    width: '100%',
    backgroundColor: '#272727',
    border: '1px solid #CDAE70',
    borderRadius: 10,
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '16vh',
    width: '100%',
    backgroundColor: '#272727',
    border: '1px solid #CDAE70',
    borderRadius: 10,
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  loadingInner: {
    width: '100%',
  },
  heading: {
    fontSize: 25,
    color: '#CDAE70',
  },
  counter: {
    fontSize: 45,
    color: '#CDAE70',
  },
};

export default styles;