const styles = {
  container : {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'black',
    padding: 20,
    flex: 1,
  },
  divider: {
    height: 20,
    width: '100%',
  },
  alert: {
    color: '#CDAE70',
    border: '1px solid #CDAE70',
    borderRadius: 10,
    padding: 10,
    display: 'flex'
  },
};

export default styles;