
import React, { createContext, useState, useEffect } from "react";
import Axios from 'axios';
import { SUMMARY_URL, COUNTRY_SUMMARY_URL, LAST_UPDATED_URL } from "../utils/constants";
import Alert from "../components/alert/alert.component";

export const ApiContext = createContext({});

const ApiWrapper = ({ children }) => {
  const [showFavourites, setShowFavouties] = useState(false);
  const [favourites, setFavourites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState();
  const [summary, setSummary] = useState({
    confirmed: 0,
    deaths: 0,
    recovered: 0,
    loaded: false,
  });
  const [countries, setCountries] = useState({
    countries: [],
    loaded: false,
  });
  const refreshSummaryData = () => {
    setLoading(true);
    Axios.get(SUMMARY_URL).then(res => {
      const getAll = res.data.features[0].attributes;
      setSummary({
        confirmed: getAll.confirmed,
        recovered: getAll.recovered,
        deaths: getAll.deaths,
        loaded: true,
      });
      setTimeout(() => setLoading(false), 0);
    });
    refreshUpdatedDate();
  }
  const refreshCountryData = () => {
    setLoading(true);
    Axios.get(COUNTRY_SUMMARY_URL).then(res => {
      const countries_ = res.data.features
      setCountries({ countries: countries_, loaded: true });
      setTimeout(() => setLoading(false), 0);
    });
    refreshUpdatedDate();
  }
  const refreshUpdatedDate = () => {
    Axios.get(LAST_UPDATED_URL).then(res => {
      const lastUpdate = res.data.editingInfo.lastEditDate;
      setLastUpdated(lastUpdate);
    });
  }

  const toggleFavourites = () => {
    localStorage.setItem('showFavourites', !showFavourites);
    setShowFavouties(!showFavourites);
  }

  const removeFavourite = (countryName) => {
    const itemIndex = favourites.findIndex(item => item === countryName);
    if (itemIndex > -1) {
      const copy = [...favourites];
      copy.splice(itemIndex, 1);
      setFavourites(copy);
      localStorage.setItem('favourites', JSON.stringify(copy));
    }
  }

  const addFavourite = (countryName) => {
    if (favourites.length < 3) {
      const copy = [...favourites];
      copy.push(countryName);
      setFavourites(copy);
      localStorage.setItem('favourites', JSON.stringify(copy));
    } else {
      Alert.alert({
        heading: 'Only 3 Favourites',
        message: 'You may only have 3 favourites at a time, please remove one before you try to add another one'
      })
    }
  }

  const value = {
    data: {
      summary,
      countries,
      lastUpdated,
    },
    favourites,
    showFavourites,
    loading,
    refreshSummaryData,
    refreshCountryData,
    toggleFavourites,
    addFavourite,
    removeFavourite,
  };
  useEffect(() => {
    if (localStorage.getItem('showFavourites')) {
      setShowFavouties(localStorage.getItem('showFavourites') === 'true');
    }
    const localStorageFav = localStorage.getItem('favourites');
    if (localStorageFav) {
      setFavourites(JSON.parse(localStorageFav));
    }
  }, []);
  return (
    <ApiContext.Provider value={value}>
      {children}
    </ApiContext.Provider>
  );
};

export default ApiWrapper;
