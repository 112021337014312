import React from 'react';
import styles from './favouriteCard.style';
import PropTypes from 'prop-types';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const FavouriteCard = ({ country, loading }) => {
  if (loading) {
    return (
      <SkeletonTheme color="#363636" highlightColor="#434343">
        <div style={styles.loadingContainer}>
          <div style={styles.loadingInner}>
            <Skeleton width="65vw" height={25} />
            <Skeleton width="50vw" height={20} />
            <Skeleton width="58vw" height={20} />
            <Skeleton width="40vw" height={20} />
          </div>
        </div>
      </SkeletonTheme>
    );
  }
  const {
    attributes: {
      recovered,
      confirmed,
      deaths,
      Country_Region: title,
    },
  } = country;
  return (
    <div style={styles.container}>
      <span style={styles.textContainer}>
        <span style={styles.heading}>{title}</span><br />
        <span style={styles.details}>Confirmed: {confirmed}</span><br />
        <span style={styles.details}>Recovered: {recovered}</span><br />
        <span style={styles.details}>Deaths: {deaths}</span>
      </span>
    </div>
  );
};

FavouriteCard.propTypes = {
  country: PropTypes.any.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default FavouriteCard;
