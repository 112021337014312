import React, { useState, useEffect } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import TutorialSteps from './tutorial.steps';

const Tutorial = ({ screen }) => {
  const [steps, setSteps] = useState([]);
  const [done, setDone] = useState(false);
  useEffect(() => {
    const tutorialDone = localStorage.getItem(`tutorialDone_${screen}`);
    if (tutorialDone && tutorialDone === 'true') {
      setDone(true);
    } else {
      setSteps(TutorialSteps[screen]);
    }
  }, [screen]);
  const handleJoyrideCallback = data => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      localStorage.setItem(`tutorialDone_${screen}`, 'true');
    }
  };
  if (!done) {
    return (
      <Joyride
        run={true}
        steps={steps}
        callback={handleJoyrideCallback}
        showProgress
        continuous
        disableOverlayClose
        disableCloseOnEsc
        showSkipButton
      />
    );
  }
  return null;
};

export default Tutorial;