
const styles = {
  container: {
    height: 40,
    fontSize: 16,
    display: 'flex',
    width: '100vw',
    color: '#AFAFAF',
    fontStyle: 'italic',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default styles;