import React from "react";
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import Main from "./pages/main/main.page";
import Countries from "./pages/countries/countries.page";
import Map from "./pages/map/map.page";
import Header from "./components/header/header.component";
import Footer from "./components/footer/footer.component";
import AnimatedPages from "./components/animatedPages/animatedPages.component";
import ApiWrapper from "./api/apiWrapper";
import ServiceWorkerManager from "./components/serviceWorkerManager/serviceWorkerManager.component";

const App = () => {
  console.log('Render App');
  return (
    <ApiWrapper>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
        backgroundColor: 'black'
      }}>
        <ServiceWorkerManager />
        <Router>
          <Header />
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 10vh - 40px)'
          }}>
            <AnimatedPages>
              <Route exact path="/">
                <Main />
              </Route>
              <Route exact path="/countries">
                <Countries />
              </Route>
              <Route exact path="/map">
                <Map />
              </Route>
            </AnimatedPages>
          </div>
          <Footer />
        </Router>
      </div>
    </ApiWrapper>
  );
}

export default App;