import React from 'react';
import { spring, AnimatedSwitch } from 'react-router-transition';

const mapStyles = (styles) => {
  return {
    opacity: styles.opacity,
    transform: `scale(${styles.scale})`,
  };
}

const bounce = (val) => (
  spring(val, {
    stiffness: 330,
    damping: 22,
  }));

const bounceTransition = {
  atEnter: {
    opacity: 0,
    scale: 0.8,
  },
  atLeave: {
    opacity: bounce(0),
  },
  atActive: {
    opacity: bounce(1),
    scale: bounce(1),
  },
};

const AnimatedPages = ({ children }) => {
  return (
    <AnimatedSwitch
      atEnter={bounceTransition.atEnter}
      atLeave={bounceTransition.atLeave}
      atActive={bounceTransition.atActive}
      mapStyles={mapStyles}
      className="route-wrapper"
    >
      {children}
    </AnimatedSwitch>
  )
};

export default AnimatedPages;
