import React, { useContext, useEffect, useState } from 'react';
import styles from './countries.style';
import CountryItem from '../../components/countryItem/countryItem.component';
import { ApiContext } from '../../api/apiWrapper';
import { Search, X } from 'react-feather';
import Tutorial from '../../components/tutorial/tutorial.component';

const Countries = () => {
  const [search, setSearch] = useState('');
  const {
    data: { countries: { countries, loaded } },
    loading,
    refreshCountryData,
  } = useContext(ApiContext);
  useEffect(() => {
    if (!loaded) {
      refreshCountryData();
    }
  }, [loaded, refreshCountryData])
  return (
    <>
      <div className="searchbar-input" style={styles.searchContainer}>
        <input
          placeholder="Search ..."
          style={styles.search}
          value={search}
          onChange={({ target: { value } }) => setSearch(value)}
        />
        <div style={styles.iconLeft}>
          <Search size={20} color="grey"/>
        </div>
        <div style={styles.iconRight} onClick={() => setSearch('')}>
          <X size={20} color="grey" />
        </div>
      </div>
      <div style={styles.container}>
        {loading && Array(20).fill(null).map((_, index) => (
          <CountryItem key={`CountryLoader-${index}`} loading={loading} />
        ))}
        {!loading && countries.filter(country =>
          country.attributes.Country_Region.toLowerCase().includes(search.toLowerCase())
        ).map((country, index) => (
          <CountryItem
            key={`${country.attributes.Country_Region}-${index}`}
            country={country}
          />
        ))}
      </div>
      <Tutorial screen="countries" />
    </>
  )
};

export default Countries;
