const styles = {
  container: {
    height: '16vh',
    width: '100%',
    backgroundColor: '#272727',
    border: '1px solid #CDAE70',
    borderRadius: 10,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingContainer: {
    height: '16vh',
    width: '100%',
    backgroundColor: '#272727',
    border: '1px solid #CDAE70',
    borderRadius: 10,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingInner: {
    textAlign: 'center',
  },
  heading: {
    fontSize: '3.5vh',
    color: '#CDAE70',
    fontWeight: 'bold'
  },
  details: {
    fontSize: '2.5vh',
    color: '#CDAE70',
  },
  textContainer: {
    textAlign: 'center',
  }
};

export default styles;