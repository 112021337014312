import React, { useContext } from 'react';
import styles from './footer.style';
import moment from 'moment';
import { ApiContext } from '../../api/apiWrapper';

const Footer = () => {
  const { data } = useContext(ApiContext);
  return (
    <div style={styles.container}>
      <span>Last updated: {moment(data.lastUpdated).format('YYYY-MM-DD HH:mm')}</span>
    </div>
  );
}

export default Footer;