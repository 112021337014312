const styles = {
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '10vh',
    width: '100vw',
    backgroundColor: '#272727',
    borderBottom: '1px solid #CDAE70',
  },
  left: {
    flex: 1,
    paddingLeft: 10,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  middel: {
    color: 'white',
    flex: 5,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    fontSize: 25,
  },
  right: {
    flex: 1,
    paddingRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  }
};

export default styles;