const styles = {
  container: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    maxHeight: 'calc(100vh - 10vh - 83px)',
  },
  searchContainer: {
    width: '100%',
    position: 'relative',
  },
  search: {
    backgroundColor: 'white',
    border: 0,
    boxSizing: 'content-box',
    fontSize: 20,
    padding: 10,
    paddingLeft: 50,
    width: '100%',
  },
  iconRight: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  iconLeft: {
    position: 'absolute',
    top: 10,
    left: 10,
  },
};

export default styles;
