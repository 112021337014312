import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './main.style';
import StatCard from '../../components/statCard/statCard.component';
import Button from '../../components/button/button.component';
import { ApiContext } from '../../api/apiWrapper';
import FavouriteCard from '../../components/favouriteCard/favouriteCard.component';
import { AlertCircle } from 'react-feather';
import Tutorial from '../../components/tutorial/tutorial.component';

const Divider = () => (
  <div style={styles.divider} />
)

const Main = () => {
  const history = useHistory();
  const { data, refreshSummaryData, refreshCountryData, loading, favourites, showFavourites } = useContext(ApiContext);
  useEffect(() => {
    if (!data.summary.loaded) {
      refreshSummaryData();
    }
    if (showFavourites && !data.countries.loaded) {
      refreshCountryData();
    }
  }, [data, refreshSummaryData, refreshCountryData, showFavourites]);
  return (
    <div style={styles.container}>
      {!showFavourites && (
        <>
          <StatCard heading="Total Confirmed" loading={loading} counter={data.summary.confirmed} />
          <Divider />
          <StatCard heading="Total Recovered" loading={loading} counter={data.summary.recovered} />
          <Divider />
          <StatCard heading="Total Deaths" loading={loading} counter={data.summary.deaths} />
          <Divider />
        </>
      )}
      {showFavourites && (
        <>
          {favourites.length && favourites.map(item => {
            if (!loading && data.countries.loaded) {
              const country = data.countries.countries.find(country => country.attributes.Country_Region === item);
              return (
                <>
                  <FavouriteCard loading={loading} country={country} />
                  <Divider />
                </>
              );
            }
            return (
              <>
                <FavouriteCard loading={true} />
                <Divider />
              </>
            );
          })}
          {!favourites.length && (
            <>
              <div style={styles.alert}>
                <div style={{ width: 50 }}>
                  <AlertCircle height={40} width={40} color="#CDAE70" />
                </div>
                <div style={{ flex: 1 }}>
                  You currently don't have any favourites added to your list, please navigate to the countries section and click on the star button to add one!
              </div>
              </div>
              <Divider />
            </>
          )}
        </>
      )}
      <Button className="countries-btn" title="COUNTRIES" onClick={() => history.push('/countries')} />
      <Divider />
      <Button className="map-btn" title="MAP" onClick={() => history.push('/map')} />
      <Tutorial screen="main" />
    </div>
  )
};

export default Main;
