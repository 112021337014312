import React from 'react';
import styles from './button.style';

const Button = ({onClick, title, className}) => {
  return (
    <div className={className} style={styles.container} onClick={onClick}>
      {title}
    </div>
  )
}

export default Button;
