import React, { useState, useEffect, useContext } from 'react';
import GoogleMapReact from 'google-map-react';
import { DarkMapTheme } from './map.theme';
import Marker from '../../components/marker/marker.component';
import { ApiContext } from '../../api/apiWrapper';
import Tutorial from '../../components/tutorial/tutorial.component';

const API_KEY = 'AIzaSyBaFoTJpr4LkF3TeDVYCqQ8byxOWcugmGc';

const Map = (props) => {
  const [center, setCenter] = useState({
    lat: 26.461857,
    lng: 28.689943,
  });
  const {
    data: { countries: { countries, loaded } },
    refreshCountryData,
  } = useContext(ApiContext);
  useEffect(() => {
    if (!loaded) {
      refreshCountryData();
    }
  }, [loaded, refreshCountryData])
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: API_KEY }}
        center={center}
        defaultZoom={2}
        options={{
          styles: DarkMapTheme,
        }}
      >
        {countries.map((country, index) => {
          const {
            attributes: {
              recovered,
              confirmed,
              deaths,
              Country_Region: title,
              lat: latitude,
              long_: longitude,
            },
          } = country;

          return (
            <Marker
              key={`${title}-${index}`}
              selected={center.lat === latitude && center.lng === longitude}
              lat={latitude}
              lng={longitude}
              country={country}
              onClick={() => {
                setCenter({ lat: latitude, lng: longitude });
                setTimeout(() => alert(`Country/Region: ${title} \nConfirmed: ${confirmed} \nRecovered: ${recovered} \nDeaths: ${deaths}`), 300);
              }}
            />
          )
        })}
      </GoogleMapReact>
      <Tutorial screen="map" />
    </div>
  );
}

export default Map;
