import React from 'react';

const Marker = ({ country, onClick, selected, }) => (
  <div
    className="map-marker"
    onClick={() => onClick()}
    style={{
      height: 20,
      width: 20,
      backgroundColor: selected ? 'blue' : 'red',
      borderRadius: '50%',
      border: '2px solid white',
    }} />
);

export default Marker;